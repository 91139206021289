<template>
    <div class="numero-mayor-menor">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Cálculo"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1617836232/juegos-cramer/tutoriales/nivel1/percepcion/mayor_y_menor_rugd6r.mp4"
        text="Marque el número mayor y el número menor."
        ></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning">
            <clock-down minuto="1" segundo="0" @setgameruning="setGameRuning()"></clock-down>
            <div class="nmm__game-box-container">
                <div class="nmm__game-box">
                    <div class="nmm__options-container">
                        <div
                            v-for="(item,index) in arrNumbers"
                            :key="item" 
                            class="nmm__option-box"
                            
                        >
                            <div @click="processOption(item,index)" class="nmm__option-value">
                                {{item}}
                            </div>
                            <div v-if="visibleAnswer&&(index==index_answer_selected[0]||index==index_answer_selected[1])" class="nmm__capa-respuesta">
                                <div :class="['nmm__check-answer',answerSelected]"></div>
                            </div>
                        </div>
                    </div>
                    <div :class="[capaProtectora]"></div>
                </div>
            </div>
        </div>
        <div v-if="!gameRuning" >
            <results-game nombrejuego="numero mayor y menor" nivel="1" inteligencia="calculo"></results-game>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import HeaderTypeGame from '@/components/HeaderTypeGame'
import TutorialGame from '@/components/TutorialGame'
import ClockDown from '@/components/ClockDown'
import ResultsGame from '@/components/ResultsGame'
import {mapState,mapMutations} from 'vuex'
export default {
    components:{
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame
    },
    data:()=>({
        puntos:0,
        gameRuning:true,
        marca:false,
        arrNumbers:[],
        numMin:0,
        numMax:0,
        numTemp:0,
        hist:[],
        visibleAnswer:false,
        index_answer_selected:[],
        capaProtectora:'',
        answerSelected:''
    }),
    computed:{
        ...mapState(['tutorialStatus'])
    },
    methods:{
        ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
        setGameRuning(){
            this.gameRuning = false
            if(this.puntos<0)
            {
                this.changeScoreGame(0)
                this.changeScoreDescription('insuficiente')
            }
            else{
                this.changeScoreGame(this.puntos)
                if(this.puntos<=200){
                this.changeScoreDescription('insuficiente')
                }
                else if(this.puntos<=400){
                    this.changeScoreDescription('escasa')
                }
                else if(this.puntos<=600){
                    this.changeScoreDescription('suficiente')
                }
                else if(this.puntos<=800){
                    this.changeScoreDescription('buena')
                }
                else{
                    this.changeScoreDescription('optima')
                }
            }
        },
        getRandom(min, max) {
                return Math.floor(Math.random() * (max - min+1) ) + min;
            },
        reordenarArray(arr){
                let a = arr.sort(function() { return Math.random() - 0.5 })
            // console.log(a)
                return a;
            },
        getArrayResRandom(min,max){
            //let total = max-min;
            let res = [];
            
            while(!(res.length==6))
            {
                let aux = this.getRandom(min,max);
                let a = res.find((e)=>e==aux)
                if(!a){
                    res.push(aux)
                }
            }
            
            return res;
        },
        processOption(item,index){
            this.index_answer_selected.push(index);
            this.visibleAnswer = true;
            this.numTemp++;
            if(this.numTemp==2)
            {
                this.capaProtectora = 'nmm__capa-protectora'
            }
            if((item==this.numMin||item==this.numMax)&&this.numTemp<=2)
            {
                this.hist.push(item)
                this.answerSelected = 'nmm__correct-answer'
                let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                a.play()
                this.getKeyAns()
            }
            else{
                let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                a.play()
                this.puntos -= 10;
                this.answerSelected = 'nmm__wrong-answer'
                this.capaProtectora = 'nmm__capa-protectora'
                window.setTimeout(()=>{
                    this.numTemp = 0
                    this.hist = []
                    this.index_answer_selected = []
                    this.capaProtectora=''
                    this.gameGenerator()
                },1000)
            }
            
        },
        getKeyAns(){
            if((this.hist[0]==this.numMin||this.hist[1]==this.numMin)&&(this.hist[0]==this.numMax||this.hist[1]==this.numMax))
            {
                //console.log("so perfecto")
                this.puntos = this.puntos + 60;
                window.setTimeout(()=>{
                    this.numTemp = 0
                    this.hist = []
                    this.index_answer_selected = []
                    this.capaProtectora=''
                    this.gameGenerator()
                },1000)

            }
            else if(this.numTemp==2){
                console.log("so macabro")
                let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                a.play()
                this.puntos = this.puntos - 10;
                this.answerSelected = 'nmm__wrong-answer'
                this.capaProtectora = 'nmm__capa-protectora'
                window.setTimeout(()=>{
                    this.numTemp = 0
                    this.hist = []
                    this.index_answer_selected = []
                    this.capaProtectora=''
                    this.gameGenerator()
                },1000)
            }
        },
        gameGenerator(){
            this.answerSelected = ''
            this.capaProtectora = ''
            let pivot = this.getRandom(11,89)
            let arr = []
            arr = this.getArrayResRandom(pivot-10,pivot+10)
            let arr_ordenado = arr.sort((a,b)=>a-b);
            let min = arr_ordenado[0];
            let max = arr_ordenado[5];
            this.numMin = min;
            this.numMax = max;
            this.arrNumbers = this.reordenarArray(arr); 
        }
    },
    mounted(){
        this.changeMenuBarBottom(false)
        this.gameGenerator()
        this.changeTutorialStatus(true)
        
    }
}
</script>
<style>
.numero-mayor-menor{
    width: 100%;
    height: 100vh;
    background-color: #1F0275;
}
.nmm__game-box-container{
    margin-top:2em;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}
.nmm__game-box{
    width: 90%;
    height: 50vh;
    background-color:  #EEEFE4;
    border-radius:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.nmm__options-container{
    width: 90%;
    height: 50%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 50% 50%;
    justify-items: center;
    align-items: center;
}
.nmm__option-box{
    background-color: rgba(172, 97, 97, 0.986);
    width: 3em;
    height: 3em;
    border-radius: 5px;
    border:solid 3px #323232;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.nmm__option-value{
    width: 100%;
    height: 100%;
    color:rgb(214, 170, 170);
    font-size: 2em;
    text-align: center;
}
.nmm__capa-respuesta{
    position: absolute;
    width: 4em;
    height: 4em;
    background-color: transparent;
    
}
.nmm__capa-protectora{
    width: 100%;
    height: 50%;
    background-color: royalblue;
    position: absolute;
    opacity: 0;
}
.nmm__check-answer{
    width: 2em;
    height: 2em;
    position: absolute;
    right: 0px;
    background-size: 100% 100%;
}
.nmm__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
.nmm__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}
@media (min-width: 900px) and (orientation: landscape) {
    .nmm__game-box{
        width: 60%;
    }
    
}
</style>